














































































































import 'codemirror/lib/codemirror.css'
import 'codemirror/addon/display/placeholder.js'
import { codemirror } from 'vue-codemirror'
import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import SenderViewModel from '../viewmodels/sender'

@Observer
@Component({
  components: {
    DropDragableUpload: () => import('@/components/drop-dragable-upload.vue'),
    MenuDownloadSample: () => import('@/components/menu-download-sample.vue'),
    codemirror
  }
})
export default class SenderPrepare extends Vue {
  @Inject() vm!: SenderViewModel

  onCmCodeChange(newCode: string) {
    this.vm.changeCodeMirrorText(newCode)
  }

  cmOption = {
    focus: true,
    placeholder: 'Insert address and amount, separate with comma',
    tabSize: 1,
    styleActiveLine: true,
    lineNumbers: true,
    line: true,
    deleting: false,
    singleCursorHeightPerLine: false
  }
  codeMirror

  async next() {
    const res = await this.vm.prepareNextAction()
    if (res) {
      // if (this.vm.selectedTokenInfo.tokenAddress) this.vm.handleEstimateGas()
      this.vm.setCurrentStep(2)
    }
  }

  uploaded(res) {
    this.vm.changeUploadFileState(false)
    this.vm.walletEditor.setValue(res)
    this.codeMirror.refresh()
  }

  async tokenInputUnFocus(event: any) {
    try {
      this.vm.addressChecking = true
      await this.vm.checkTokenAddress(event.target.value)
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      this.vm.addressChecking = false
    }
  }

  async tokenChange(token: string) {
    this.vm.changeState({ selectedToken: token })
  }

  mounted() {
    this.codeMirror = this.$refs.codeMirror!
    this.vm.setWalletEditor((this.codeMirror as any).codemirror.doc)
  }
}
